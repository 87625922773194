import { render } from "./CreateSpaceForm.vue?vue&type=template&id=5c42b653"
import script from "./CreateSpaceForm.vue?vue&type=script&lang=js"
export * from "./CreateSpaceForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c42b653"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c42b653', script)) {
    api.reload('5c42b653', script)
  }
  
  module.hot.accept("./CreateSpaceForm.vue?vue&type=template&id=5c42b653", () => {
    api.rerender('5c42b653', render)
  })

}

script.__file = "src/components/dashboard/CreateSpaceForm.vue"

export default script